import AlertYesNo from '@/components/AlertYesNo.vue'
import InputText from '@/components/inputs/InputText'
import { CheckboxCheckedRow, Checkbox } from '@/utils/icons'
export const newRowMixin = {
  components: { AlertYesNo, InputText },
  emits: ['save', 'cancel'],
  data() {
    return {
      CheckboxCheckedRow,
      Checkbox,
      isVisibleConfirmAlert: false,
      textConfirmAlert: '',
    }
  },

  props: {
    rowData: { default: () => ({}), type: Object },
    columns: {
      type: Object,
      default: () => ({}),
    },
    setWidthColumn: {
      default: () => {
        return ''
      },
      type: Function,
    },
  },

  methods: {
    formatDateToQuery(date) {
      if (
        date &&
        Object.prototype.toString.call(date) === '[object Date]' &&
        !isNaN(date)
      ) {
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month

        return `${year}-${month}-${day}`
      } else if (date) {
        return date
      }

      return null
    },

    rowForSave() {
      let newRow = {}

      for (const key in this.$data) {
        let entityValue = this.$data[key]
        if (
          key === 'date_published' ||
          key === 'date_requested' ||
          key === 'issued_date'
        )
          newRow = {
            ...newRow,
            ...{ [key]: this.formatDateToQuery(entityValue.value) },
          }
        else if (typeof entityValue === 'object' && 'isValid' in entityValue) {
          if ('isMultiselect' in entityValue) {
            if (entityValue.isMultiselect && entityValue.value?.length) {
              newRow = {
                ...newRow,
                ...{ [key]: entityValue.value },
              }
            } else if (
              !entityValue.isMultiselect &&
              entityValue.value?.length
            ) {
              newRow = {
                ...newRow,
                ...{ [key]: entityValue.value[0] },
              }
            } else {
              newRow = { ...newRow, ...{ [key]: null } }
            }
          } else {
            newRow = { ...newRow, ...{ [key]: entityValue.value } }
          }
        }
      }
      return newRow
    },

    getColumn(key) {
      if (!key) return

      return this.columns.find((item) => item.key === key)
    },

    alertConfirmResult() {
      this.isVisibleConfirmAlert = false
    },

    isValidUrl(keyStatus, keyUrl) {
      if (
        this[keyStatus].value &&
        (this[keyStatus].value.includes('Pitched') ||
          this[keyStatus].value.includes('Published'))
      ) {
        let tmpReplasedUrl = (this[keyUrl].value || '')
          .replace('https://', '')
          .replace('http://', '')
        let firstIdx = tmpReplasedUrl.indexOf('/')
        if (firstIdx < 0) return false

        let tmpSlicedUrl = tmpReplasedUrl.slice(firstIdx + 1)

        if (tmpSlicedUrl.length < 7) return false
      }
      return true
    },
  },
}
