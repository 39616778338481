<template>
  <div>
    <header-page :title="'Niches'" :hasBackLink="false" />

    <div class="filters-container">
      <div class="filters-block">
        <div class="search">
          <input-text v-model="search_text" />
          <div
            v-if="isShowClear"
            class="main-button lg ml-15"
            @click="clearFilters"
          >
            <div>Clear</div>
          </div>
        </div>
        <div class="button">
          <div v-if="false" class="main-button lg orange" @click="setFilters">
            <div class="icon" v-html="Filter"></div>
            <div>Filters</div>
          </div>
        </div>
      </div>
      <div class="selected-filters" v-if="selectedFilters.length">
        <div v-for="item in selectedFilters" :key="item.text">
          <div class="icon" @click="removeFilter(item.id)" v-html="Cross"></div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewNiche"
      @saveRow="saveNiche"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
    />
    <set-filters v-if="isVisibleFilters" v-model="isVisibleFilters" />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import NewRowNiche from '@/components/NewRows/NewRowNiche'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'Niches',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isSelectable: false,
        isEditable: hasAccess(permissions.update_niche),
        isAdd: hasAccess(permissions.create_niche),
        isDelete: hasAccess(permissions.delete_niche),
        addButtonText: 'Add Niche',
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">
            Are you sure you want to delete niche?
            <div>Niche <b>${rows[0].niche}</b></div>
            <div>Sub niches ${rows[0].sub_niches
              .map((item) => `<b>${item.sub_niche}</b>`)
              .join(', ')}</div>
            </div>`
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'niche',
          text: 'Niche',
          width: '250px',
        }),
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'sub_niches',
          text: 'Sub Niche',
          formatedValue: ({ value }) => {
            if (typeof value === 'object' && value.length) {
              return value
                .map((item) => {
                  //title="${item.sub_niche}"
                  let isIncludesString = false
                  let className = ''
                  if (this.search_textToGrid?.length > 2)
                    isIncludesString = item.sub_niche
                      .toString()
                      .toLowerCase()
                      .includes(this.search_textToGrid.toLowerCase())
                  if (isIncludesString) className = 'class="includes"'
                  return `<span ${className}>${item.sub_niche}</span>`
                })
                .join(', ')
            }
            return ''
          },
          width: '500px',
        }),
      ],
      newNiche: {
        niche: null,
        sub_niches: null,
      },
      newRowComponent: shallowRef(NewRowNiche),
    }
  },

  created() {
    this.setDataFromQueryParams() // 3
    this.fetchData()
  },

  methods: {
    saveNewNiche(niche) {
      this.setNewNiche(niche)
      this.addRow()
    },

    async saveNiche(niche) {
      await this.editRow(niche)
    },

    bodyToSave(entity) {
      //не получилось использовать функцию из миксина в силу не шаблонного типа принимаемых параметров на беке
      let entityLocal = {
        niche: entity.niche,
        sub_niches: {
          sub_niches: [],
        },
      }

      entityLocal.sub_niches.sub_niches = (entity.sub_niches || []).map(
        (item) => item.sub_niche
      )

      return entityLocal
    },

    newNicheToDefault() {
      for (let key in this.newNiche) {
        this.newNiche[key] = null
      }
    },

    setNewNiche(niche) {
      for (let key in niche) {
        this.newNiche[key] = niche[key]
      }
    },

    async editRow(niche) {
      this.$store.commit('setIsLoading', true)
      const id = niche.id
      delete niche.id

      let tmpNiche = JSON.parse(JSON.stringify(niche))
      delete tmpNiche.sub_niches

      let result = await fetchData(
        urls.niches + `/${id}`,
        'POST',
        this.bodyToSave(tmpNiche)
      )
      if (!result?.detail) {
        let nicheRow = this.rows.find((item) => item.id === id)

        for (const key in nicheRow) {
          if (key in nicheRow) nicheRow[key] = result[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      let requests = niche.sub_niches
        .filter((item) => 'type' in item)
        .map((item) => {
          if (item.type === 'delete') return this.deleteSubNiche(item.id, id)
          if (item.type === 'add') return this.addSubNiche(item.sub_niche, id)
          if (item.type === 'edit')
            return this.editSubNiche(item.id, item.sub_niche, id)
        })

      await Promise.all(requests).catch((err) => console.error(err))

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.nichesCreate,
        'POST',
        this.bodyToSave(this.newNiche)
      )
      if (!result?.detail) {
        this.rows.unshift(result)
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newNicheToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(id) {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(urls.niches + `/${id}`, 'DELETE')
      if (!result?.detail) {
        const idx = this.rows.findIndex((item) => item.id === id)
        this.rows.splice(idx, 1)
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async deleteSubNiche(id, niche_id) {
      //this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.subNiches + `/${id}` + `?niche_id=${niche_id}`,
        'DELETE'
      )
      if (!result?.detail) {
        const niche = this.rows.find((item) => item.id === niche_id)
        const idx = niche.sub_niches.findIndex((item) => item.id === id)
        niche.sub_niches.splice(idx, 1)
      } else console.error(result.detail)

      //this.$store.commit('setIsLoading', false)
    },

    async addSubNiche(sub_niche, niche_id) {
      let result = await fetchData(urls.subNiches + `/${-1}`, 'POST', {
        niche_id: niche_id,
        sub_niche_name: sub_niche,
      })
      if (!result?.detail) {
        const niche = this.rows.find((item) => item.id === niche_id)
        niche.sub_niches.push(result)
      } else console.error(result.detail)
    },

    async editSubNiche(id, sub_niche, niche_id) {
      let result = await fetchData(urls.subNiches + `/${id}`, 'POST', {
        niche_id: niche_id,
        sub_niche_name: sub_niche,
      })
      if (!result?.detail) {
        const niche = this.rows.find((item) => item.id === niche_id)
        const sub_niche = niche.sub_niches.find((item) => item.id === id)
        delete sub_niche.type
        sub_niche.sub_niche = result.sub_niche
      } else console.error(result.detail)
    },

    async fetchData() {
      this.setQueryParams()

      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += this.queryToSort()

      let result = await fetchData(urls.niches, 'GET', null, query)

      this.rows = (result?.rows || []).map((item) => {
        return {
          id: item.id,
          niche: item.niche,
          sub_niches: item.sub_niches,
        }
      })

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    setFilters() {
      this.isVisibleFilters = !this.isVisibleFilters
    },

    removeFilter(id) {
      const idx = this.selectedFilters.findIndex((item) => item.id === id)
      if (idx > -1) this.selectedFilters.splice(idx, 1)
    },
  },
}
</script>
