<template>
    <Teleport to="body">
        <div class="modal-container sub-niches-modal">
            <div class="modal-block">
                <div class="modal-title">
                    <div class="title-text">
                        <div>{{title}}</div> 
                    </div>
                </div>
                <div class="modal-body">
                    <div class="modal-row">
                        <input-text v-model="newNiche"/>
                        <div class="main-button add" @click="add">Add</div>
                    </div>
                    <div class="scrolable-block">
                        <div class="modal-row" v-for="subNiche in subNichesModel" :key="subNiche.id">
                           <div class="w-100" :class="{'error': subNiche.type === 'delete'}">
                            <div v-if="selectedSubNiche && selectedSubNiche.id === subNiche.id" class="editable-block">
                                <div class="label"><input-text v-model="editableSubNiche"/></div>
                                <div class="icons" v-if="subNiche.type !== 'delete'">
                                    <div class="icon ok" @click="saveEdited" v-html="Ok"></div>
                                    <div class="icon cancel" @click="selectedSubNiche = null" v-html="Cross2"></div>
                                </div>
                            </div>
                            <div v-else class="editable-block">
                                <div class="label">{{subNiche.sub_niche}}</div>
                                <div class="icons" v-if="subNiche.type !== 'delete'">
                                    <div class="icon edit" @click="edit(subNiche)" v-html="Edit"></div>
                                    <div class="icon delete" @click="subNiche.type = 'delete'" v-html="Delete"></div>
                                </div>
                            </div>
                           </div>
                        </div>
                    </div>
                    <div class="modal-row buttons">
                        <div class="main-button orange" @click="apply">Apply</div>
                        <div class="main-button" @click="alertResult(false)">Close</div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport> 
</template>

<script>
import InputText from '@/components/inputs/InputText'
import { Edit, Delete, Ok, Cross2 } from '@/utils/icons'

export default {
    name: 'NewRowNicheModal',
    emits: ['update:modelValue', 'alertResult'],
    components: { InputText },
    props: {
        modelValue: {           
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: 'Alert'
        },

        alertResult: {
            type: Function,
            default: () => {}
        }
    },

    data() {
        return {
            Delete, Edit, Ok, Cross2,      
            newNiche: {
                placeholder: 'Add subniche',
                value: null,
                isValid: true,
                clearable: false,
                required: false,
                readonly: false,
                errorKey: '',
                class: 'mr-15'
            },
            editableSubNiche: {
                value: null,
                isValid: true,
                clearable: false,
                required: false,
                readonly: false,
                errorKey: '',
            },
            subNichesLocal: [],
            selectedSubNiche: null
        }
    },

    created() {
        this.subNichesLocal = [...this.subNichesModel]
    },

    computed:{
        subNichesModel: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },

    methods: {
        saveEdited() {
            this.selectedSubNiche.sub_niche =  this.editableSubNiche.value
            if(!this.selectedSubNiche.type) this.selectedSubNiche.type = 'edit'
            this.selectedSubNiche = null
        },

        edit(subNiche) {
            this.selectedSubNiche = subNiche
            this.editableSubNiche.value = subNiche.sub_niche
        },
        
        apply() {
            this.subNichesModel.filter(item => !('type' in item)).forEach(item => {
              const baseValue = this.subNichesLocal.find(itemBase => itemBase.id === item.id)
              if(baseValue && baseValue.sub_niche !== item.sub_niche) item.type = 'edit'
              if(baseValue && !item.sub_niche) item.type = 'delete'
            })

            this.alertResult(true)
        },
        add() {
            if(!this.newNiche.value) return

            this.subNichesModel.push({ id: this.newNiche.value, sub_niche: this.newNiche.value, type: 'add' })
            this.newNiche.value = null

        }
    }
}
</script>

<style lang="scss">
.sub-niches-modal {
    .modal-block{
        width: 500px;
    }

    .error {
        color: #FE7248;
    }

    .scrolable-block {
        max-height: 550px;
        overflow: auto;
        margin: 10px 0;
    }

    .mr-15 {
        margin-right: 15px;
    }

    .add{
        min-width: 80px;
        justify-content: center;
    }

    .editable-block {
        display: flex;
        align-items: center;

        .label {
            width: 100%;
            margin-right: 19px;
        }
    }

    .icons {
        display: flex;
        .icon {
            margin: 0!important;
            padding: 0 10px!important;
            svg {
                stroke: none!important;
                fill: #858688!important;
                width: auto!important;
            }

            &.delete {
                svg {
                    height: 20px!important;
                }
            }

            &.edit {
                svg {
                    height: 20px!important;
                }
            }

            &.ok {
                padding-top: 5px!important;
                svg {
                    height: 16px!important;
                }
            }

            &.cancel {
                padding-top: 5px!important;
                svg {
                    height: 16px!important;
                }
            }
        }
    }



    .buttons {
        justify-content: space-between;
        .main-button {
            width: 80px;
            justify-content: center;
            &:first-of-type {
                margin-right: 40px;
            }
        }
    }
}

</style>