<template>
  <tr class="add-row">
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('niche'))">
        <input-text v-model="niche" />
      </div>
    </td>
    <td @click="showModal">
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('sub_nichesFormated'))"
      >
        <input-text v-model="sub_nichesFormated" />
      </div>
      <new-row-niche-modal
        v-if="isShowModal"
        v-model="sub_nichesModal"
        title="Edit niches"
        :alertResult="alertResult"
      />
    </td>
  </tr>
</template>

<script>
import NewRowNicheModal from '@/components/NewRows/NewRowNicheModal'
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
  name: 'NewRowNiche',
  mixins: [newRowMixin],
  components: { NewRowNicheModal },
  data() {
    return {
      niche: {
        value: '',
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      sub_nichesFormated: {
        value: '',
        isValid: true,
        clearable: false,
        required: true,
        readonly: true,
        errorKey: '',
        class: 'sm hand',
      },

      sub_niches: [],
      isShowModal: false,
      sub_nichesModal: [],
    }
  },

  created() {
    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        if (key === 'sub_niches') {
          this.$data['sub_nichesFormated'].value = element
            .map((item) => item.sub_niche)
            .join(', ')
          this.$data['sub_nichesFormated'].isValid = true
          this.sub_niches = element
        } else this.$data[key].value = element

        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }
  },

  computed: {
    isValid() {
      if (!this.niche.isValid) return false

      return true
    },
  },

  methods: {
    showModal() {
      if (this.rowData?.sub_niches)
        this.sub_nichesModal = [...(this.rowData.sub_niches || [])]

      this.isShowModal = true
    },

    alertResult(result) {
      if (!result) {
        if (this.rowData?.sub_niches) {
          this.sub_nichesModal = [...this.rowData.sub_niches]
          this.rowData.sub_niches.forEach((element) => {
            delete element.type
          })
          this.sub_nichesFormated.value = this.rowData?.sub_niches
            .map((item) => item.sub_niche)
            .join(', ')
        } else {
          this.sub_nichesModal = []
          this.sub_nichesFormated.value = null
        }
      } else {
        if (this.rowData?.sub_niches) {
          //this.sub_nichesModal = [...this.rowData.sub_niches]
          this.sub_nichesFormated.value = this.rowData?.sub_niches
            .map((item) => item.sub_niche)
            .join(', ')
        } else
          this.sub_nichesFormated.value = this.sub_nichesModal
            .map((item) => item.sub_niche)
            .join(', ')
      }

      this.isShowModal = false
    },

    cancel() {
      this.$emit('cancel')
    },

    save() {
      if (!this.isValid) return

      const newRow = {
        niche: this.niche.value,
        sub_niches: this.sub_nichesModal,
      }

      this.$emit('save', newRow)
    },
  },
}
</script>
